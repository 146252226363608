import React, { useEffect, useState } from 'react';
import OfferBanner from '../OfferBanner';
import { AlertWrapper, TabContentWrapper } from '../UI/styledConstants';
import { getAepsBankList, cashwithdrawal, balanceEnquiry, miniStatement, getCheckAepsStatus, merchantLoginFingerprient } from '../../utils/api';
import { overLayers, isEmpty } from '../../utils/common';
import { parseString } from 'xml2js';
import Loader from '../UI/Loader/Loader';
import AepsReceipt from './AepsReceipt';

const transactionTypeList = [
	{ value: 'Withdrawal', label: 'Withdrawal ' },
	{ value: 'enquiry', label: 'Balance Enquiry' },
	{ value: 'statement', label: 'Mini Statement' },
];
const initialControls = {
	transactionType: '',
	aadharNumber: '',
	amount: '',
	bank: '',
	mobileNumber: '',
};

export default function AEPS({ className = '' }) {
	const [bankList, setBankList] = useState(null);
	const [filterBankList, setFilterBankList] = useState(null);
	const [bankDataResp, setBankDataResp] = useState({});
	const [dropDown, setDropdown] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [message, setMessage] = useState({});

	const [controls, setControls] = useState({ ...initialControls });

	const [overLayer, setOverLayer] = useState(false);
	const [loading, setLoading] = useState(false);

	const number = /^[0-9]+$/;
	const nameReg = /^[A-Za-z\s]+$/i;

	const onChangeHandler = (e) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}
		if (e.target.name === 'amount' || e.target.name === 'aadharNumber' || e.target.name === 'mobileNumber') {
			if (!number.test(e.target.value) && e.target.value) {
				return false;
			}
		}

		setControls({
			...controls,
			[e.target.name]: e.target.value,
		});

		if (e.target.name === 'bank') {
			setDropdown(false);
			setSearchKey(e.target?.nextElementSibling?.innerHTML);
		}
	};

	const onSubmitHandler = () => {
		if (controls.mobileNumber === '' || controls.mobileNumber === '' || controls.transactionType === '' || controls.bank === '') {
			setMessage({ type: 'error', msg: 'All field are required', place: 'form' });

			return;
		} else if (controls.mobileNumber.length !== 10) {
			setMessage({ type: 'error', msg: 'Enter 10 digit mobile number', place: 'form' });

			return;
		} else if (controls.aadharNumber.length !== 12) {
			setMessage({ type: 'error', msg: 'Enter 12 digit aadhar number', place: 'form' });

			return;
		} else if (controls.transactionType === 'Withdrawal' && controls.amount < 1) {
			setMessage({ type: 'error', msg: 'Enter Amount', place: 'form' });

			return;
		}

		const body = {
			mobileNumber: controls.mobileNumber,
			bankIIN: controls.bank,
			adhaarNumber: controls.aadharNumber,
			amount: 0,
			pid_xml_base64e: '',
			externalRef: '',
			latitude: '78.35583746',
			longitude: '17.3644687',
		};

		if (controls.transactionType === 'Withdrawal') {
			body.amount = controls.amount;
		} else {
			body.amount = 0;
		}
		checkStatus(body);
	};

	useEffect(() => {
		getAepsBankList().then((res) => {
			if (res?.data) {
				setBankList(res?.data);
			}
		});
	}, []);

	function Capture(param, type) {
		var url = 'http://127.0.0.1:11100/capture';

		var PIDOPTS =
			'<PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" otp="" posh="UNKNOWN" env="P" wadh="" /> <Demo></Demo><CustOpts> <Param name="ValidationKey" value="" /> </CustOpts> </PidOptions>';

		var xhr;
		//var ua = window.navigator.userAgent;
		xhr = new XMLHttpRequest();
		xhr.open('CAPTURE', url, true);
		xhr.setRequestHeader('Content-Type', 'text/xml');
		xhr.setRequestHeader('Accept', 'text/xml');

		xhr.onreadystatechange = function () {
			//if(xhr.readyState == 1 && count == 0){
			//	fakeCall();
			//}
			if (xhr.readyState === 4) {
				var status = xhr.status;
				//parser = new DOMParser();
				if (status === 200) {
					var test1 = xhr.responseText;
					var test2 = test1.search('errCode');
					var test6 = getPosition(test1, '"', 2);
					var test4 = test2 + 9;
					var test5 = test1.slice(test4, test6);
					if (test5 > 0) {
						setMessage({ type: 'error', msg: 'FingerPrint Scanned Failed', place: 'globle' });
						setLoading(false);
						//document.getElementById('t;ext').value = xhr.responseText;
					} else {
						convertXmlToJs(xhr.responseText)
							.then((result) => {
								const qscore = result?.PidData?.Resp[0]?.$.qScore;
								let abc = qscore.replace('\n', '');
								let resp_ = result?.PidData?.Resp[0]?.$;
								resp_ = { ...resp_, qScore: abc };

								const fingerPrirntData = {
									errInfo: result?.PidData?.Resp[0]?.$?.errInfo,
									errorCode: result?.PidData?.Resp[0]?.$?.errCode,
									message: 'FingerPrint Scanned Successfully',
									pidDataJson: {
										PidData: {
											Data: {
												content: result?.PidData?.Data[0]?._,
												type: result?.PidData?.Data[0]?.$?.type,
											},
											DeviceInfo: {
												additional_info: {
													Param: [
														{
															name: 'serial_number',
															value: result?.PidData?.DeviceInfo[0]?.additional_info[0]?.Param[0]?.$?.name,
														},
														{
															name: result?.PidData?.DeviceInfo[0]?.additional_info[0]?.Param[0]?.$?.name,
															value: result?.PidData?.DeviceInfo[0]?.additional_info[0]?.Param[0]?.$?.name,
														},
													],
												},
												dc: result?.PidData?.DeviceInfo[0]?.$?.dc,
												dpId: result?.PidData?.DeviceInfo[0]?.$?.dpId,
												mc: result?.PidData?.DeviceInfo[0]?.$?.mc,
												mi: result?.PidData?.DeviceInfo[0]?.$?.mi,
												rdsId: result?.PidData?.DeviceInfo[0]?.$?.rdsId,
												rdsVer: result?.PidData?.DeviceInfo[0]?.$?.rdsVer,
											},
											Hmac: result?.PidData?.Hmac[0],
											Resp: resp_,
											Skey: {
												ci: result?.PidData?.Skey[0]?.$.ci,
												content: result?.PidData?.Skey[0]?._,
											},
										},
									},
									pidDataXML: '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' + xhr?.responseText,
								};

								param.pid_xml_base64e = fingerPrirntData;
								if (type === 'customer') {
									if (controls.transactionType === 'Withdrawal') {
										cashwithdrawal(param).then((res) => {
											setLoading(false);
											if (res?.data) {
												setControls({ ...initialControls });
												setOverLayer(true);
												overLayers(true);
												setBankDataResp(res.data);
											} else {
												setOverLayer(false);
												if (res?.errorCodeList.length > 0) {
													setMessage({ type: 'error', msg: res?.errorCodeList[0], place: 'globle' });
												} else {
													setMessage({ type: 'error', msg: res?.msg, place: 'globle' });
												}
											}
										});
									}
									if (controls.transactionType === 'enquiry') {
										balanceEnquiry(param).then((res) => {
											setLoading(false);
											if (res?.data) {
												setControls({ ...initialControls });
												setOverLayer(true);
												overLayers(true);
												setBankDataResp(res.data);
											} else {
												setOverLayer(false);
												if (res?.errorCodeList.length > 0) {
													setMessage({ type: 'error', msg: res?.errorCodeList[0], place: 'globle' });
												} else {
													setMessage({ type: 'error', msg: res?.msg, place: 'globle' });
												}
											}
										});
									}
									if (controls.transactionType === 'statement') {
										miniStatement(param).then((res) => {
											setLoading(false);
											if (res?.data) {
												setControls({ ...initialControls });
												setOverLayer(true);
												overLayers(true);
												setBankDataResp(res.data);
											} else {
												setOverLayer(false);
												if (res?.errorCodeList.length > 0) {
													setMessage({ type: 'error', msg: res?.errorCodeList[0], place: 'globle' });
												} else {
													setMessage({ type: 'error', msg: res?.msg, place: 'globle' });
												}
											}
										});
									}
								} else {
									merchantFingerPrient(fingerPrirntData);
								}
							})
							.catch((error) => {
								console.error('XML parsing error:', error);
								setLoading(false);
							});

						//document.getElementById('text').value = "Captured Successfully";
					}
				} else {
					setLoading(false);
				}
			}
		};

		xhr.send(PIDOPTS);
	}

	function getPosition(string, subString, index) {
		return string.split(subString, index).join(subString).length;
	}

	function convertXmlToJs(xmlData) {
		return new Promise((resolve, reject) => {
			parseString(xmlData, (err, result) => {
				if (err) {
					reject(err);
				} else {
					resolve(result);
				}
			});
		});
	}

	const handleFilter = (event) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}
		if (bankList) {
			const keyword = event.target.value;
			if (!nameReg.test(keyword) && keyword) {
				return false;
			}

			setSearchKey(keyword.toUpperCase());

			const pattern = new RegExp('^' + keyword, 'i');
			const filtered = bankList.filter((item, index) => {
				return pattern.test(item.bankName);
			});

			if (keyword) {
				setFilterBankList(filtered.slice(0, 4));
			} else {
				setFilterBankList(null);
			}
		}
	};

	function closePop() {
		setOverLayer(false);
		setBankDataResp({});
		setLoading(false);
		overLayers(false);
		setSearchKey('');
		setMessage({});
		setControls({ ...initialControls });
	}

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const checkStatus = (body) => {
		setLoading(true);
		getCheckAepsStatus().then((resp) => {
			if (resp?.success) {
				setMessage({ type: 'success', msg: 'Please scan customer fingerprint', place: 'globle' });
				Capture(body, 'customer');
				setLoading(true);
			} else {
				setLoading(false);
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
					setTimeout(() => {
						setMessage({ type: 'success', msg: 'Please scan merchant fingerprint', place: 'globle' });
						Capture({}, 'merchant');
					}, 3000);
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	};

	const merchantFingerPrient = (param) => {
		const data = {
			data: param,
		};
		setLoading(true);
		merchantLoginFingerprient(data).then((resp) => {
			setLoading(false);
			if (resp?.success) {
				setMessage({ type: 'success', msg: 'Merchant Login successfull You can continue', place: 'globle' });
			} else {
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	};

	return (
		<TabContentWrapper className={`tab-content-wrapper ${className}`}>
			{!isEmpty(message) && message.place === 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
			<div className="tab-content-left">
				<h2>AEPS Transaction</h2>
				<div className='flex'>
				<div className="form-field">
					<select className="item-select" placeholder="Operator" value={controls.transactionType} name="transactionType" onChange={onChangeHandler}>
						<option value="" selected>
							Transaction Type
						</option>
						{transactionTypeList.map((option, index) => {
							return (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							);
						})}
					</select>
				</div>
				<div className="form-field">
					<input type="text" name="aadharNumber" value={controls.aadharNumber} placeholder="Aadhaar No." maxLength={12} onChange={onChangeHandler} />
				</div>
				<div className="form-field">
					<input type="text" name="mobileNumber" value={controls.mobileNumber} placeholder="Mobile No." maxLength={10} onChange={onChangeHandler} />
				</div>
				{controls.transactionType === 'Withdrawal' && (
					<div className="form-field">
						<input type="text" name="amount" value={controls.amount} placeholder="Amount" onChange={onChangeHandler} />
					</div>
				)}

				<div className="form-field form-field-select-bank">
					{/* <div className="field-label">Select Bank</div> */}
					<div className="form-field searchBank">
						<input
							type="text"
							className="search"
							name="amount"
							style={{ marginBottom: '0' }}
							placeholder="Search Bank..."
							value={searchKey}
							onChange={handleFilter}
							onFocus={(e) => setDropdown(true)}
						/>
					</div>
					{dropDown && (
						<div className="radio-group">
							{filterBankList &&
								filterBankList.map((val, index) => {
									return (
										<label key={index} className={controls.bank === val?.iinno ? 'radio active' : 'radio'}>
											<input type="radio" name="bank" value={val?.iinno} onChange={onChangeHandler} />
											<span className="icon-with-name">{val?.bankName}</span>
										</label>
									);
								})}
						</div>
					)}
				</div>
				{!isEmpty(message) && message.place === 'form' && (
					<AlertWrapper className={message.type}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				<button type="submit" className="button-outline" onClick={onSubmitHandler}>
					SUBMIT
				</button>
				</div>
				
			</div>

			<div className="tab-content-right">{!loading && <OfferBanner />}</div>

			{loading && <Loader />}
			{overLayer && <AepsReceipt closePop={closePop} transactionType={controls.transactionType} bankDataResp={bankDataResp} />}
		</TabContentWrapper>
	);
}
