import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: var(--section-gbColor-V2);
	padding: 50px 0px 7% 59px;
	    margin-top: 60px;

	box-shadow: 0 1px 1px #dfdfdf;
	overflow: hidden;
	max-width: 1428px;
    margin: 0 auto;

	.img {
		flex:0 1 535px;
		img {
			margin-bottom: -27px;
			height: 300px;
    width: 300px;
    border-radius: 50%;

		
	}
	.about-text {
		flex: 0 1 600px;

		h2 {
			margin-bottom: 14px;
		}
		p {
			font-size: 20px;
			line-height: 35px;
			color: var(--tab-link-color-hover);
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 20px 0;
		.container {
			flex-direction: column-reverse;

			& > div {
				margin: 0 auto 0;
				flex: 0 1 auto;
				padding-top: 20px;
                padding-bottom: 81px;
} 
			}
		}
	}
`;
