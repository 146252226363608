import React, { useState, useEffect, useContext } from 'react';
import MobileRechargeForm from '../../Components/MobileRechargeForm';

import Electricity from '../../Components/Electricity';
import DTH from '../../Components/DTH';
import Exploremore from '../../Components/Exploremore';
import Sendmoney from '../../Components/Sendmoney';
import { Wrapper } from './style';
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";
import AEPS from '../AEPS';
import CreditCard from '../CreditCard/CreditCard';
import { tabs } from './constants';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isEmpty } from '../../utils/common';
import { GlobleContaxt } from '../../Context/GlobleContaxt';

var settings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 4,
	initialSlide: 0,
	responsive: [
	  {
		breakpoint: 1024,
		settings: {
		  slidesToShow: 4,
		  slidesToScroll: 4,
		
		  dots: true
		}
	  },
	  {
		breakpoint: 600,
		settings: {
		  slidesToShow: 4,
		  slidesToScroll: 4,
		 
		}
	  },
	  {
		breakpoint: 480,
		settings: {
		  slidesToShow: 3,
		  slidesToScroll: 3
		}
	  }
	]
  };





function Component() {
	const [activeTab, setActiveTab] = useState("tab2");
	const [moreList, setMoreList] = useState(false);
	const [permission, setPermission] = useState([]);
	const {userDetails, apiPermission} = useContext(GlobleContaxt);


	useEffect(() =>{
		
		if(!isEmpty(apiPermission)){
			const permissionArr = [];
			apiPermission.map((val) =>{
				permissionArr.push(val?.navCode)
			})
			setPermission(permissionArr);
		
		}
	},[apiPermission])
	

	const tabContentMap = {
		tab1: <MobileRechargeForm className="mobile-recharge-form" />,
		tab2: <Electricity className="electricity-form" BillType={"ELECTRICITY"} billHeading={"Electricity"} />,
		tab3: <CreditCard className="electricity-form"/>,
		tab4: <Electricity className="electricity-form" BillType={"BROADBAND"} billHeading={"Broadband"}/>,
		tab5: <DTH className="dth-form" />,
		tab6: <AEPS className="aeps-form" />,
		tab7: <Sendmoney className='send-money-form' />,
		tab9: <Exploremore className='Exploremore' moreList={moreList} setMoreList={setMoreList} />,
		tab10:  <Electricity className="electricity-form" BillType={'WATER'} billHeading={'Water'} />,
		
	}

	

	
	

	const handleTab = (id) => {
		setActiveTab(id);
	  };

	  const getMoreOption =(id)=>{
		setActiveTab(id);
		moreList?setMoreList(false):setMoreList(true);
	  }
	return (
		<Wrapper className='home-page'>
		
			<div className="header-nav">
			<Slider {...settings}>
				{
					tabs.map((data, i) => 
					(data.show === 'all')?
						<TabNavItem 
							key={i}
							title={data?.title} 
							icon={data?.icon} 
							id={data?.id} 
							activeTab={activeTab} 
							setActiveTab={handleTab} 
							isActive={activeTab ===data.id}
						/>
						:(!isEmpty(userDetails?.userName)) && (permission.includes(data.apiPermission))?
						<TabNavItem 
							key={i}
							title={data?.title} 
							icon={data?.icon} 
							id={data?.id} 
							activeTab={activeTab} 
							setActiveTab={handleTab} 
							isActive={activeTab ===data.id}
						/>:""

					)
				}
				<TabNavItem 
							key={9}
							title={"Explore More"} 
							icon={"IconExploreMore"} 
							id={"tab9"} 
							activeTab={activeTab} 
							setActiveTab={getMoreOption} 
							isActive={activeTab === "tab9"}
						/>
				
				</Slider>

			</div>
		
			{
				Object.keys(tabContentMap).map((tabId, i) => {
					return(
						<TabContent key={i} id={tabId} activeTab={activeTab}>
							{tabContentMap[tabId]}
						</TabContent>
					)
				})
			}
		</Wrapper>
	);
}

export default Component;
