import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 22px;
  }
  html {
  scroll-behavior: smooth;
}
  body {
    font-family: 'Rubik', sans-serif;
    background-color: #f6f6f6;
  
    color: #141414;
    -webkit-font-smoothing: antialiased;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 470px;
      background-color: var(--header-bg_color);
      z-index: -1;
    }
  }
  button, input {
    outline: none;
  }
  .container {
    max-width: 1471px;;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
  }
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-2{
  font-size: 30px;
   font-weight: 500;
   color: var(--heading_2-color);
   line-height: 40px;
}
.heading-2 .strong{

   font-weight: 700;
  
}
  ul,li{
    list-style:none;
  }
  a{
        text-decoration: none;
  }
  img{
    vertical-align: top;
  }
  .button-solid {
    background-color: var(--login-btn-bg);
    color: var(--white);
    border: 1px solid var(--login-btn-bg);
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    padding: 0 40px;
    font-weight: 700;
    cursor: pointer;
  }
  .button-outline {
    background-color: var(--button-bg-color);
    border: 2px solid var(--login-btn-bg);
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    padding: 0 10px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    transition: all.3s;
  }

  .browse-plans {
		font-weight: 500;
		font-size: 12px;
		margin: 18px 0 24px;
		text-decoration: underline;
		color: var(--login-btn-bg);
		font-weight: bold;
		cursor: pointer;
	}

  .button-outline:hover{
    background-color: var(--login-btn-bg);
    color: var(--white);
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .flex {
    display: flex;
  }
  .items-center {
    align-items: center;
  }
  .self-center{
    align-self: center;
  }
  .self-end{
    align-self: flex-end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-end {
    justify-content: flex-end;
  }

  .justify-center{
    justify-content: center;
  }
  .text-center{
    text-align: center;
  }
  .page-msg {
    margin-bottom: 20px;
  }
  .error {
    color: red;
    font-size: 12px;
  }
  .success {
    color: green;
    font-size: 12px;
  }
  .mb30{
    margin-bottom: 30px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt25{
    margin-top: 25px;
  }
  .mt30{
    margin-top: 30px;
  }
  .mt50{
    margin-top: 50px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .bold {
    font-weight: 600;
  }
  .medium {
    font-weight: 500;
  }
  .italic {
    font-style: italic;
  }
  .gap-20{
    gap: 20px;
  }
  .gap-50{
    gap: 50px;
  }
  .gap-30{
    gap: 30px;
  }
  .gap-100{
    gap: 100px;
  }
  .w-100{
    width: 100%;
  }
  .row {
    display: flex;
    margin: 0 -12px;
    [class*="col-"] {
      padding: 0 12px;
    }
  }
  .col-1 {
    flex: 0 0 calc(100% * 1 / 12);
  }
  .col-2 {
    flex: 0 0 calc(100% * 2 / 12);
  }
  .col-3 {
    flex: 0 0 calc(100% * 3 / 12);
  }
  .col-4 {
    flex: 0 0 calc(100% * 4 / 12);
  }
  .col-5 {
    flex: 0 0 calc(100% * 5 / 12);
  }
  .col-6 {
    flex: 0 0 calc(100% * 6 / 12);
  }
  .col-7 {
    flex: 0 0 calc(100% * 7 / 12);
  }
  .col-8 {
    flex: 0 0 calc(100% * 8 / 12);
  }
  .col-9 {
    flex: 0 0 calc(100% * 9 / 12);
  }
  .col-10 {
    flex: 0 0 calc(100% * 10 / 12);
  }
  .col-11 {
    flex: 0 0 calc(100% * 11 / 12);
  }
  .col-12 {
    flex: 0 0 calc(100% * 12 / 12);
  }
  .heading h2{
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
  .heading label{
    text-align: center;
    margin-bottom: 40px;
  }
  .d-m-block{
    display: none;
  }
  
  .d-m-fade{
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
  }

  .csv-link{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 13px;
    border: none;
    line-height: 20px;
    background-color: rgb(0, 186, 242);
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
    .btn-loader{
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
  }
  
  .grow-1{
    flex-grow: 1;
  }
  @media only screen and (max-width: 767px){
    .d-m-block{
    display: block;
  }
  .d-m-fade{
    visibility: visible;
    opacity: 1;
   
  }
  
  .d-m-none{
    display: none;
  }
  .personal-information{
    width: 100%;
  }
  
  }
  @media print {
		body{
        visibility: hidden;
		
    }
   
  }
 
`;

export default GlobalStyle;
