import styled from 'styled-components';

export const Wrapper = styled.div`
	background-image: url('../../assets/images/bharatBanner.png');
	background-position-x: 100px;
    background-position-y: 24px;
	background-repeat: no-repeat;
	background-color: var(--tab-content-bgColo);
	padding-left: 47px;
	height: calc(100% + 60px); 
	display: flex;
    justify-content: flex-end;
	background-color: #19ACA5;


	.banner-text {
		padding: 80px 80px 20px 0px;

		h1 {
			font-size: 35px;
			line-height: 42px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
		}
		.flat-text {
			font-size: 26px;
			line-height: 39px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 13px;
		}
		i {
			color: #fff;
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
`;
